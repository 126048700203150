<template>
  <CommonLayout>
    <div class="cabinet">
      <div class="cabinet__inner">
        <router-link
          class="button-default button-back uni-back"
          :to="{ name: 'profile' }"
        >
          <span></span>Назад
        </router-link>
        <div class="cabinet__head">
          <!-- <router-link
            class="button-default button-back cabinet__back"
            :to="{ name: 'rating', params: { type: 'team' } }"
            ><span></span>Назад</router-link
          > -->
          <div class="title">Настройки писем</div>
          <div class="subtitle">
            Вы можете включить или отключить получение каждого из писем
          </div>
        </div>
        <div class="cabinet__body">
          <div class="cabinet__content">
            <div class="settings">
              <div class="settings-list">
                <!-- TeamNextIslandTemplate = 4, -->
                <div
                  v-if="
                    list[TeamNextIslandTemplate] &&
                    list[TeamNextIslandTemplate].templateId === 4
                  "
                  class="settings-list__item"
                  :class="{ active: list[TeamNextIslandTemplate].allowed }"
                >
                  <div class="settings-list__title">Открыта новая планета</div>
                  <div class="settings-list__switcher">
                    <label class="uni-switch">
                      <input
                        v-model="list[TeamNextIslandTemplate].allowed"
                        type="checkbox"
                        @change="onChange(list[TeamNextIslandTemplate])"
                      />
                      <span></span>
                    </label>
                  </div>
                </div>
                <!-- ReturnTemplate = 6, -->
                <div
                  v-if="
                    list[ReturnTemplate] &&
                    list[ReturnTemplate].templateId === 6
                  "
                  class="settings-list__item"
                  :class="{ active: list[ReturnTemplate].allowed }"
                >
                  <div class="settings-list__title">
                    Вы давно не заходили в игру
                  </div>
                  <div class="settings-list__switcher">
                    <label class="uni-switch">
                      <input
                        v-model="list[ReturnTemplate].allowed"
                        type="checkbox"
                        @change="onChange(list[ReturnTemplate])"
                      />
                      <span></span>
                    </label>
                  </div>
                </div>
                <!-- OrderDeliveredTemplate = 7, -->
                <!-- <div
                  v-if="
                    list[OrderDeliveredTemplate] &&
                    list[OrderDeliveredTemplate].templateId === 7
                  "
                  class="settings-list__item"
                  :class="{ active: list[OrderDeliveredTemplate].allowed }"
                >
                  <div class="settings-list__title">Заказ доставлен</div>
                  <div class="settings-list__switcher">
                    <label class="uni-switch">
                      <input
                        v-model="list[OrderDeliveredTemplate].allowed"
                        type="checkbox"
                        @change="onChange(list[OrderDeliveredTemplate])"
                      />
                      <span></span>
                    </label>
                  </div>
                </div> -->
                <!-- WeeklyNewTasks = 8, -->
                <div
                  v-if="
                    list[WeeklyNewTasks] &&
                    list[WeeklyNewTasks].templateId === 8
                  "
                  class="settings-list__item"
                  :class="{ active: list[WeeklyNewTasks].allowed }"
                >
                  <div class="settings-list__title">
                    Назначены новые задания
                  </div>
                  <div class="settings-list__switcher">
                    <label class="uni-switch">
                      <input
                        v-model="list[WeeklyNewTasks].allowed"
                        type="checkbox"
                        @change="onChange(list[WeeklyNewTasks])"
                      />
                      <span></span>
                    </label>
                  </div>
                </div>
                <!-- TeamQuestAppointed = 10, -->
                <div
                  v-if="
                    list[TeamQuestAppointed] &&
                    list[TeamQuestAppointed].templateId === 10
                  "
                  class="settings-list__item"
                  :class="{ active: list[TeamQuestAppointed].allowed }"
                >
                  <div class="settings-list__title">
                    Командная цель: назначена
                  </div>
                  <div class="settings-list__switcher">
                    <label class="uni-switch">
                      <input
                        v-model="list[TeamQuestAppointed].allowed"
                        type="checkbox"
                        @change="onChange(list[TeamQuestAppointed])"
                      />
                      <span></span>
                    </label>
                  </div>
                </div>
                <!-- TeamQuestCompleted = 11, -->
                <div
                  v-if="
                    list[TeamQuestCompleted] &&
                    list[TeamQuestCompleted].templateId === 11
                  "
                  class="settings-list__item"
                  :class="{ active: list[TeamQuestCompleted].allowed }"
                >
                  <div class="settings-list__title">
                    Командная цель: завершена
                  </div>
                  <div class="settings-list__switcher">
                    <label class="uni-switch">
                      <input
                        v-model="list[TeamQuestCompleted].allowed"
                        type="checkbox"
                        @change="onChange(list[TeamQuestCompleted])"
                      />
                      <span></span>
                    </label>
                  </div>
                </div>
                <!-- QuestCompleted = 12, -->
                <div
                  v-if="
                    list[QuestCompleted] &&
                    list[QuestCompleted].templateId === 12
                  "
                  class="settings-list__item"
                  :class="{ active: list[QuestCompleted].allowed }"
                >
                  <div class="settings-list__title">Задание выполнено</div>
                  <div class="settings-list__switcher">
                    <label class="uni-switch">
                      <input
                        v-model="list[QuestCompleted].allowed"
                        type="checkbox"
                        @change="onChange(list[QuestCompleted])"
                      />
                      <span></span>
                    </label>
                  </div>
                </div>
                <!-- BattleFillingStarted = 13, -->
                <div
                  v-if="
                    list[BattleFillingStarted] &&
                    list[BattleFillingStarted].templateId === 13
                  "
                  class="settings-list__item"
                  :class="{ active: list[BattleFillingStarted].allowed }"
                >
                  <div class="settings-list__title">
                    Поединок: начался этап «Сбор заявок на участие»
                  </div>
                  <div class="settings-list__switcher">
                    <label class="uni-switch">
                      <input
                        v-model="list[BattleFillingStarted].allowed"
                        type="checkbox"
                        @change="onChange(list[BattleFillingStarted])"
                      />
                      <span></span>
                    </label>
                  </div>
                </div>
                <!-- BattleFightStarted = 14, -->
                <div
                  v-if="
                    list[BattleFightStarted] &&
                    list[BattleFightStarted].templateId === 14
                  "
                  class="settings-list__item"
                  :class="{ active: list[BattleFightStarted].allowed }"
                >
                  <div class="settings-list__title">
                    Поединок: начался этап «Поединок»
                  </div>
                  <div class="settings-list__switcher">
                    <label class="uni-switch">
                      <input
                        v-model="list[BattleFightStarted].allowed"
                        type="checkbox"
                        @change="onChange(list[BattleFightStarted])"
                      />
                      <span></span>
                    </label>
                  </div>
                </div>
                <!-- BattleCompleted = 15, -->
                <div
                  v-if="
                    list[BattleCompleted] &&
                    list[BattleCompleted].templateId === 15
                  "
                  class="settings-list__item"
                  :class="{ active: list[BattleCompleted].allowed }"
                >
                  <div class="settings-list__title">
                    Поединок: результаты поединка опубликованы
                  </div>
                  <div class="settings-list__switcher">
                    <label class="uni-switch">
                      <input
                        v-model="list[BattleCompleted].allowed"
                        type="checkbox"
                        @change="onChange(list[BattleCompleted])"
                      />
                      <span></span>
                    </label>
                  </div>
                </div>
                <!-- TuesdayQuestsNotTaken = 16 -->
                <div
                  v-if="
                    list[TuesdayQuestsNotTaken] &&
                    list[TuesdayQuestsNotTaken].templateId === 16
                  "
                  class="settings-list__item"
                  :class="{ active: list[TuesdayQuestsNotTaken].allowed }"
                >
                  <div class="settings-list__title">
                    Назначенные задания еще не взяты
                  </div>
                  <div class="settings-list__switcher">
                    <label class="uni-switch">
                      <input
                        v-model="list[TuesdayQuestsNotTaken].allowed"
                        type="checkbox"
                        @change="onChange(list[TuesdayQuestsNotTaken])"
                      />
                      <span></span>
                    </label>
                  </div>
                </div>
              </div>
              <div v-if="limit" class="cabinet-table__more">
                <button @click="limit = null">Смотреть все</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<script>
import {
  GetPlayerMailSettings,
  UpdatePlayerMailSettings,
} from "@/api/PlayerMailSettings.js";
import CommonLayout from "@/layout/CommonLayout.vue";

export default {
  components: {
    CommonLayout,
  },
  data() {
    return {
      loading: true,
      limit: 9,
      list: [],
    };
  },
  computed: {
    normalizeList() {
      if (this.limit) {
        return this.list.map((e) => e).slice(0, this.limit);
      }
      return this.list;
    },
    // CaptainQuestTemplate = 3,
    CaptainQuestTemplate() {
      return this.normalizeList.findIndex((e) => e.templateId === 3);
    },
    // TeamNextIslandTemplate = 4,
    TeamNextIslandTemplate() {
      return this.normalizeList.findIndex((e) => e.templateId === 4);
    },
    // ReturnTemplate = 6,
    ReturnTemplate() {
      return this.normalizeList.findIndex((e) => e.templateId === 6);
    },
    // OrderDeliveredTemplate = 7,
    OrderDeliveredTemplate() {
      return this.normalizeList.findIndex((e) => e.templateId === 7);
    },
    // WeeklyNewTasks = 8,
    WeeklyNewTasks() {
      return this.normalizeList.findIndex((e) => e.templateId === 8);
    },
    // CaptainTeamQuestsAvailable = 9,
    CaptainTeamQuestsAvailable() {
      return this.normalizeList.findIndex((e) => e.templateId === 9);
    },
    // TeamQuestAppointed = 10,
    TeamQuestAppointed() {
      return this.normalizeList.findIndex((e) => e.templateId === 10);
    },
    // TeamQuestCompleted = 11,
    TeamQuestCompleted() {
      return this.normalizeList.findIndex((e) => e.templateId === 11);
    },
    // QuestCompleted = 12,
    QuestCompleted() {
      return this.normalizeList.findIndex((e) => e.templateId === 12);
    },
    // BattleFillingStarted = 13,
    BattleFillingStarted() {
      return this.normalizeList.findIndex((e) => e.templateId === 13);
    },
    // BattleFightStarted = 14,
    BattleFightStarted() {
      return this.normalizeList.findIndex((e) => e.templateId === 14);
    },
    // BattleCompleted = 15,
    BattleCompleted() {
      return this.normalizeList.findIndex((e) => e.templateId === 15);
    },
    // TuesdayQuestsNotTaken = 16
    TuesdayQuestsNotTaken() {
      return this.normalizeList.findIndex((e) => e.templateId === 16);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const resp = await GetPlayerMailSettings();
        this.list = resp.data;
        this.loading = false;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        this.loading = false;
      }
    },
    async onChange(item) {
      try {
        await UpdatePlayerMailSettings({
          settings: [{ ...item }],
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        item.allowed = !item.allowed;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  margin-top: 44px;
  margin-bottom: 44px;
}
.settings-list {
  display: grid;
  gap: 8px;
  &__item {
    background: rgba(0, 255, 254, 0.1);
    padding: 16px 36px;
    height: 78px;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr auto;
    align-items: center;
    transition: all 0.4s cubic-bezier(0.17, 0.67, 0.43, 0.98);
    &.active {
      background: rgba(0, 255, 254, 0.25);
    }
  }
  &__title {
    font-weight: 700;
    font-size: 20px;
  }
  &__switcher {
  }
}
</style>
