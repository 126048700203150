import request from "@/utils/request";

export function GetPlayerMailSettings(query) {
  return request({
    url: "/api/Player/PlayerMailSettings/GetPlayerMailSettings",
    method: "get",
    params: query,
  });
}

export function UpdatePlayerMailSettings(data) {
  return request({
    url: "/api/Player/PlayerMailSettings/UpdatePlayerMailSettings",
    method: "post",
    data,
  });
}
