var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CommonLayout',[_c('div',{staticClass:"cabinet"},[_c('div',{staticClass:"cabinet__inner"},[_c('router-link',{staticClass:"button-default button-back uni-back",attrs:{"to":{ name: 'profile' }}},[_c('span'),_vm._v("Назад ")]),_c('div',{staticClass:"cabinet__head"},[_c('div',{staticClass:"title"},[_vm._v("Настройки писем")]),_c('div',{staticClass:"subtitle"},[_vm._v(" Вы можете включить или отключить получение каждого из писем ")])]),_c('div',{staticClass:"cabinet__body"},[_c('div',{staticClass:"cabinet__content"},[_c('div',{staticClass:"settings"},[_c('div',{staticClass:"settings-list"},[(
                  _vm.list[_vm.TeamNextIslandTemplate] &&
                  _vm.list[_vm.TeamNextIslandTemplate].templateId === 4
                )?_c('div',{staticClass:"settings-list__item",class:{ active: _vm.list[_vm.TeamNextIslandTemplate].allowed }},[_c('div',{staticClass:"settings-list__title"},[_vm._v("Открыта новая планета")]),_c('div',{staticClass:"settings-list__switcher"},[_c('label',{staticClass:"uni-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.list[_vm.TeamNextIslandTemplate].allowed),expression:"list[TeamNextIslandTemplate].allowed"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.list[_vm.TeamNextIslandTemplate].allowed)?_vm._i(_vm.list[_vm.TeamNextIslandTemplate].allowed,null)>-1:(_vm.list[_vm.TeamNextIslandTemplate].allowed)},on:{"change":[function($event){var $$a=_vm.list[_vm.TeamNextIslandTemplate].allowed,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.list[_vm.TeamNextIslandTemplate], "allowed", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.list[_vm.TeamNextIslandTemplate], "allowed", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.list[_vm.TeamNextIslandTemplate], "allowed", $$c)}},function($event){return _vm.onChange(_vm.list[_vm.TeamNextIslandTemplate])}]}}),_c('span')])])]):_vm._e(),(
                  _vm.list[_vm.ReturnTemplate] &&
                  _vm.list[_vm.ReturnTemplate].templateId === 6
                )?_c('div',{staticClass:"settings-list__item",class:{ active: _vm.list[_vm.ReturnTemplate].allowed }},[_c('div',{staticClass:"settings-list__title"},[_vm._v(" Вы давно не заходили в игру ")]),_c('div',{staticClass:"settings-list__switcher"},[_c('label',{staticClass:"uni-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.list[_vm.ReturnTemplate].allowed),expression:"list[ReturnTemplate].allowed"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.list[_vm.ReturnTemplate].allowed)?_vm._i(_vm.list[_vm.ReturnTemplate].allowed,null)>-1:(_vm.list[_vm.ReturnTemplate].allowed)},on:{"change":[function($event){var $$a=_vm.list[_vm.ReturnTemplate].allowed,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.list[_vm.ReturnTemplate], "allowed", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.list[_vm.ReturnTemplate], "allowed", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.list[_vm.ReturnTemplate], "allowed", $$c)}},function($event){return _vm.onChange(_vm.list[_vm.ReturnTemplate])}]}}),_c('span')])])]):_vm._e(),(
                  _vm.list[_vm.WeeklyNewTasks] &&
                  _vm.list[_vm.WeeklyNewTasks].templateId === 8
                )?_c('div',{staticClass:"settings-list__item",class:{ active: _vm.list[_vm.WeeklyNewTasks].allowed }},[_c('div',{staticClass:"settings-list__title"},[_vm._v(" Назначены новые задания ")]),_c('div',{staticClass:"settings-list__switcher"},[_c('label',{staticClass:"uni-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.list[_vm.WeeklyNewTasks].allowed),expression:"list[WeeklyNewTasks].allowed"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.list[_vm.WeeklyNewTasks].allowed)?_vm._i(_vm.list[_vm.WeeklyNewTasks].allowed,null)>-1:(_vm.list[_vm.WeeklyNewTasks].allowed)},on:{"change":[function($event){var $$a=_vm.list[_vm.WeeklyNewTasks].allowed,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.list[_vm.WeeklyNewTasks], "allowed", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.list[_vm.WeeklyNewTasks], "allowed", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.list[_vm.WeeklyNewTasks], "allowed", $$c)}},function($event){return _vm.onChange(_vm.list[_vm.WeeklyNewTasks])}]}}),_c('span')])])]):_vm._e(),(
                  _vm.list[_vm.TeamQuestAppointed] &&
                  _vm.list[_vm.TeamQuestAppointed].templateId === 10
                )?_c('div',{staticClass:"settings-list__item",class:{ active: _vm.list[_vm.TeamQuestAppointed].allowed }},[_c('div',{staticClass:"settings-list__title"},[_vm._v(" Командная цель: назначена ")]),_c('div',{staticClass:"settings-list__switcher"},[_c('label',{staticClass:"uni-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.list[_vm.TeamQuestAppointed].allowed),expression:"list[TeamQuestAppointed].allowed"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.list[_vm.TeamQuestAppointed].allowed)?_vm._i(_vm.list[_vm.TeamQuestAppointed].allowed,null)>-1:(_vm.list[_vm.TeamQuestAppointed].allowed)},on:{"change":[function($event){var $$a=_vm.list[_vm.TeamQuestAppointed].allowed,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.list[_vm.TeamQuestAppointed], "allowed", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.list[_vm.TeamQuestAppointed], "allowed", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.list[_vm.TeamQuestAppointed], "allowed", $$c)}},function($event){return _vm.onChange(_vm.list[_vm.TeamQuestAppointed])}]}}),_c('span')])])]):_vm._e(),(
                  _vm.list[_vm.TeamQuestCompleted] &&
                  _vm.list[_vm.TeamQuestCompleted].templateId === 11
                )?_c('div',{staticClass:"settings-list__item",class:{ active: _vm.list[_vm.TeamQuestCompleted].allowed }},[_c('div',{staticClass:"settings-list__title"},[_vm._v(" Командная цель: завершена ")]),_c('div',{staticClass:"settings-list__switcher"},[_c('label',{staticClass:"uni-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.list[_vm.TeamQuestCompleted].allowed),expression:"list[TeamQuestCompleted].allowed"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.list[_vm.TeamQuestCompleted].allowed)?_vm._i(_vm.list[_vm.TeamQuestCompleted].allowed,null)>-1:(_vm.list[_vm.TeamQuestCompleted].allowed)},on:{"change":[function($event){var $$a=_vm.list[_vm.TeamQuestCompleted].allowed,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.list[_vm.TeamQuestCompleted], "allowed", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.list[_vm.TeamQuestCompleted], "allowed", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.list[_vm.TeamQuestCompleted], "allowed", $$c)}},function($event){return _vm.onChange(_vm.list[_vm.TeamQuestCompleted])}]}}),_c('span')])])]):_vm._e(),(
                  _vm.list[_vm.QuestCompleted] &&
                  _vm.list[_vm.QuestCompleted].templateId === 12
                )?_c('div',{staticClass:"settings-list__item",class:{ active: _vm.list[_vm.QuestCompleted].allowed }},[_c('div',{staticClass:"settings-list__title"},[_vm._v("Задание выполнено")]),_c('div',{staticClass:"settings-list__switcher"},[_c('label',{staticClass:"uni-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.list[_vm.QuestCompleted].allowed),expression:"list[QuestCompleted].allowed"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.list[_vm.QuestCompleted].allowed)?_vm._i(_vm.list[_vm.QuestCompleted].allowed,null)>-1:(_vm.list[_vm.QuestCompleted].allowed)},on:{"change":[function($event){var $$a=_vm.list[_vm.QuestCompleted].allowed,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.list[_vm.QuestCompleted], "allowed", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.list[_vm.QuestCompleted], "allowed", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.list[_vm.QuestCompleted], "allowed", $$c)}},function($event){return _vm.onChange(_vm.list[_vm.QuestCompleted])}]}}),_c('span')])])]):_vm._e(),(
                  _vm.list[_vm.BattleFillingStarted] &&
                  _vm.list[_vm.BattleFillingStarted].templateId === 13
                )?_c('div',{staticClass:"settings-list__item",class:{ active: _vm.list[_vm.BattleFillingStarted].allowed }},[_c('div',{staticClass:"settings-list__title"},[_vm._v(" Поединок: начался этап «Сбор заявок на участие» ")]),_c('div',{staticClass:"settings-list__switcher"},[_c('label',{staticClass:"uni-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.list[_vm.BattleFillingStarted].allowed),expression:"list[BattleFillingStarted].allowed"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.list[_vm.BattleFillingStarted].allowed)?_vm._i(_vm.list[_vm.BattleFillingStarted].allowed,null)>-1:(_vm.list[_vm.BattleFillingStarted].allowed)},on:{"change":[function($event){var $$a=_vm.list[_vm.BattleFillingStarted].allowed,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.list[_vm.BattleFillingStarted], "allowed", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.list[_vm.BattleFillingStarted], "allowed", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.list[_vm.BattleFillingStarted], "allowed", $$c)}},function($event){return _vm.onChange(_vm.list[_vm.BattleFillingStarted])}]}}),_c('span')])])]):_vm._e(),(
                  _vm.list[_vm.BattleFightStarted] &&
                  _vm.list[_vm.BattleFightStarted].templateId === 14
                )?_c('div',{staticClass:"settings-list__item",class:{ active: _vm.list[_vm.BattleFightStarted].allowed }},[_c('div',{staticClass:"settings-list__title"},[_vm._v(" Поединок: начался этап «Поединок» ")]),_c('div',{staticClass:"settings-list__switcher"},[_c('label',{staticClass:"uni-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.list[_vm.BattleFightStarted].allowed),expression:"list[BattleFightStarted].allowed"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.list[_vm.BattleFightStarted].allowed)?_vm._i(_vm.list[_vm.BattleFightStarted].allowed,null)>-1:(_vm.list[_vm.BattleFightStarted].allowed)},on:{"change":[function($event){var $$a=_vm.list[_vm.BattleFightStarted].allowed,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.list[_vm.BattleFightStarted], "allowed", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.list[_vm.BattleFightStarted], "allowed", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.list[_vm.BattleFightStarted], "allowed", $$c)}},function($event){return _vm.onChange(_vm.list[_vm.BattleFightStarted])}]}}),_c('span')])])]):_vm._e(),(
                  _vm.list[_vm.BattleCompleted] &&
                  _vm.list[_vm.BattleCompleted].templateId === 15
                )?_c('div',{staticClass:"settings-list__item",class:{ active: _vm.list[_vm.BattleCompleted].allowed }},[_c('div',{staticClass:"settings-list__title"},[_vm._v(" Поединок: результаты поединка опубликованы ")]),_c('div',{staticClass:"settings-list__switcher"},[_c('label',{staticClass:"uni-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.list[_vm.BattleCompleted].allowed),expression:"list[BattleCompleted].allowed"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.list[_vm.BattleCompleted].allowed)?_vm._i(_vm.list[_vm.BattleCompleted].allowed,null)>-1:(_vm.list[_vm.BattleCompleted].allowed)},on:{"change":[function($event){var $$a=_vm.list[_vm.BattleCompleted].allowed,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.list[_vm.BattleCompleted], "allowed", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.list[_vm.BattleCompleted], "allowed", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.list[_vm.BattleCompleted], "allowed", $$c)}},function($event){return _vm.onChange(_vm.list[_vm.BattleCompleted])}]}}),_c('span')])])]):_vm._e(),(
                  _vm.list[_vm.TuesdayQuestsNotTaken] &&
                  _vm.list[_vm.TuesdayQuestsNotTaken].templateId === 16
                )?_c('div',{staticClass:"settings-list__item",class:{ active: _vm.list[_vm.TuesdayQuestsNotTaken].allowed }},[_c('div',{staticClass:"settings-list__title"},[_vm._v(" Назначенные задания еще не взяты ")]),_c('div',{staticClass:"settings-list__switcher"},[_c('label',{staticClass:"uni-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.list[_vm.TuesdayQuestsNotTaken].allowed),expression:"list[TuesdayQuestsNotTaken].allowed"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.list[_vm.TuesdayQuestsNotTaken].allowed)?_vm._i(_vm.list[_vm.TuesdayQuestsNotTaken].allowed,null)>-1:(_vm.list[_vm.TuesdayQuestsNotTaken].allowed)},on:{"change":[function($event){var $$a=_vm.list[_vm.TuesdayQuestsNotTaken].allowed,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.list[_vm.TuesdayQuestsNotTaken], "allowed", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.list[_vm.TuesdayQuestsNotTaken], "allowed", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.list[_vm.TuesdayQuestsNotTaken], "allowed", $$c)}},function($event){return _vm.onChange(_vm.list[_vm.TuesdayQuestsNotTaken])}]}}),_c('span')])])]):_vm._e()]),(_vm.limit)?_c('div',{staticClass:"cabinet-table__more"},[_c('button',{on:{"click":function($event){_vm.limit = null}}},[_vm._v("Смотреть все")])]):_vm._e()])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }